import {useRecoilValue} from "recoil";
import {globalBrandConfigState} from "../../../shared/_common/global_state";
import AddToHomeScreenModal_Antos from "./view/antos";
import AddToHomeScreenModal_Exco from "./view/excotrades";
import {isMobile} from "react-device-detect"

const AddToHomeScreenModal = () => {
    const brandConfigState = useRecoilValue(globalBrandConfigState);
    const doNotRemind = localStorage.getItem("dnr_timestamp")

    const doNotRemindOnClick = (e) => {
        localStorage.setItem("dnr_timestamp", e.target.checked ? Date.now() : null);
    }

    const getIsModalOpen = () => {
        if (!isMobile) return false

        if (doNotRemind) {
            const currentTime = Date.now()
            const timeDifference = currentTime - doNotRemind
            const differenceInDays = timeDifference / (1000 * 60 * 60 * 24)
            return Math.floor(differenceInDays) > 6
        } else return true
    }

    const props = {
        open: getIsModalOpen(),
        doNotRemindOnClick
    }

    let component
    switch (brandConfigState?.brandName) {
        case "antos":
            component = <AddToHomeScreenModal_Antos {...props}/>
            break
        case "excotrades":
            component = <AddToHomeScreenModal_Exco {...props}/>
    }

    return component
}

export default AddToHomeScreenModal