import React, {useCallback, useEffect, useState} from "react";
import RouterComponent from "./shared/router";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    globalBrandConfigState,
    globalBrandSettingState,
    globalColorThemeState,
    globalCurrentPathState,
    globalCurrentPreloadStatusState,
    globalInitLoadingState,
    globalLanguageLoadingState,
    globalMessageState,
    globalNotificationState,
    globalRunAuthState,
    globalUserDetailState,
    globalUserVerifyState,
} from "./shared/_common/global_state";
import getConfigByName from "./config";
import {ConfigProvider, message, notification, theme} from "antd";
import {useVerification} from "./shared/_common/_authorize";
import usePreload from "./shared/_common/_preload";
import LoadingScreen from "./brand/_auth/loadingScreen/view";
import {Helmet} from "react-helmet";
import {MODE_PRODUCTION} from "./config/_variable";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import EmptyData from "./brand/component/emptyData/view";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import {refreshUserClaimMission} from "./shared/api/graphql/mission";
import AddToHomeScreenModal from "./brand/component/addToHomeScreenModal";

dayjs.extend(utc)
dayjs.extend(quarterOfYear)

function App() {
    let location = useLocation();
    let {i18n} = useTranslation();

    const [dataLoading, setDataLoading] = useState(true);
    const [brandLoading, setBrandLoading] = useState(true);
    const [layoutLoading, setLayoutLoading] = useState(true);
    const [languageLoading, setLanguageLoading] = useRecoilState(globalLanguageLoadingState);
    const initLoading = useRecoilValue(globalInitLoadingState);

    const [brandConfigState, setBrandConfigState] = useRecoilState(globalBrandConfigState);
    const [preloadStatus, setPreloadStatus] = useRecoilState(globalCurrentPreloadStatusState);
    const brandSettingState = useRecoilValue(globalBrandSettingState);
    const verifyState = useRecoilValue(globalUserVerifyState);
    const runAuthState = useRecoilValue(globalRunAuthState);
    const notificationState = useRecoilValue(globalNotificationState);
    const messageState = useRecoilValue(globalMessageState);
    const setGlobalPathState = useSetRecoilState(globalCurrentPathState);
    const [colorState, setColorState] = useRecoilState(globalColorThemeState);
    const userDetailState = useRecoilValue(globalUserDetailState);
    const urlSearchParams = new URLSearchParams(location.search);
    const token = urlSearchParams.get("token");
    const type = urlSearchParams.get("type");
    const lang = urlSearchParams.get("lang");
    const userIdStorage = localStorage.getItem("userid") || null;
    const tokenStorage = localStorage.getItem("a_t") || null;
    const affiliate = urlSearchParams.get("affiliate");
    const currentBrand = brandConfigState?.brandName;
    const {getClientDeviceInfo} = useVerification();

    const {
        getInitialDataWithoutToken,
        getUserPreferenceNavigations
    } = usePreload();

    const {
        authorizeUser,
        userGhostLogin,
        trackUserActivity,
        startTokenExpirationCheck,
        handleTokenExpiration,
        verifyCryptoAddressFunction,
        checkTabActivityOnOpening,
    } = useVerification();

    const [api, contextHolder] = notification.useNotification();
    const [messageApi, messageContextHolder] = message.useMessage();

    const openNotificationWithIcon = (type, msg, title, key) => {
        api[type]({
            message: title,
            description: msg,
            key: key,
            closeIcon: false,
            placement: "top"
        });
    };

    const openMessage = (type, content, duration, key) => {
        messageApi.open({
            type: type,
            content: content,
            duration: duration || 3,
            key: key,
        });
    };

    const brandConfig = async () => {
        const config = await getConfigByName();
        localStorage.setItem("graphqlURL", config.graphqlURL);
        localStorage.setItem("instrumentsURL", config.instrumentsURL);
        setBrandConfigState(config);
    };

    const layoutCss = async () => {
        await setLayoutLoading(true);
        if (brandConfigState?.defaultLayout) {
            import(`./shared/css/${"layout" + brandConfigState?.defaultLayout}/style.css`)
                .then(() => {
                    console.log("CSS file imported");
                })
                .catch((error) => {
                    console.error("Error importing CSS file:", error);
                });
        }
        setLayoutLoading(false);
    };

    const brandColorCss = async () => {
        const colorStorage = await (localStorage.getItem("color") || brandSettingState?.theme);

        switch (colorStorage) {
            case "dark":
                localStorage.setItem("color", "dark");
                setColorState("dark");
                break;
            case "light":
                localStorage.setItem("color", "light");
                setColorState("light");
                break;
        }
    };

    const brandFontCss = () => {
        import(`./brand/font/${currentBrand}.css`)
            .then(() => {
                console.log("CSS file imported");
            })
            .catch((error) => {
                console.error("Error importing CSS file:", error);
            });
    };

    const preloadApp = useCallback(async () => {
        await setPreloadStatus(true);
        await setDataLoading(true);

        const graphql = localStorage.getItem("graphqlURL") || null;

        if (graphql) {
            if (languageLoading) {
                setLanguageLoading(false)
            }

            await getInitialDataWithoutToken()
            if (token) {
                await userGhostLogin(token);
            } else if (type === "verify-crypto-address") {
                const email = urlSearchParams.get("email");
                const transactionNo = urlSearchParams.get("transaction");
                await verifyCryptoAddressFunction(email, transactionNo);
                await authorizeUser(tokenStorage, userIdStorage);
            } else if (runAuthState === false) {
                await authorizeUser(tokenStorage, userIdStorage);
            }

            if (tokenStorage) {
                await setDataLoading(false);
                refreshUserClaimMission(userIdStorage)
            }
        }
    }, [token, brandConfigState, userIdStorage]);

    const setupInitialLanguage = async () => {
        let initLang = userDetailState?.language || localStorage.getItem("language") || lang || "en";
        localStorage.setItem("language", initLang);
        i18n.changeLanguage(initLang);
    };

    const preloadBrand = async () => {
        await setupInitialLanguage();
        await setBrandLoading(true);
        await brandConfig();
        await brandFontCss();
        setBrandLoading(false);
    };

    const runVerifyingFunction = async () => {
        if (verifyState === true) {
            await checkTabActivityOnOpening();
            await trackUserActivity(10 * 60 * 1000, handleTokenExpiration);
            await startTokenExpirationCheck(60 * 1000);
        }
    };

    useEffect(() => {
        setupInitialLanguage();
        getUserPreferenceNavigations(userDetailState);
    }, [userDetailState, lang]);

    useEffect(() => {
        preloadBrand();
    }, []);

    useEffect(() => {
        brandColorCss()
    }, [brandSettingState, colorState])

    useEffect(() => {
        layoutCss();
    }, [brandConfigState, token, currentBrand, colorState]);

    useEffect(() => {
        if (!preloadStatus && brandConfigState) {
            preloadApp();
        }
    }, [token, brandConfigState, userIdStorage, preloadStatus]);

    useEffect(() => {
        runVerifyingFunction();
    }, [verifyState]);

    useEffect(() => {
        const {status, msg, title, key} = notificationState;
        if (msg && key !== "systemNotification") {
            openNotificationWithIcon(status, msg, title, key);
        } else if (MODE_PRODUCTION === false) {
            openNotificationWithIcon(status, msg, title, key);
        }
    }, [notificationState]);

    useEffect(() => {
        const {type, content, duration, key} = messageState;
        if (content) {
            openMessage(type, content, duration, key);
        }
    }, [messageState]);

    useEffect(() => {
        setPreloadStatus(false);
    });

    useEffect(() => {
        setGlobalPathState(location.pathname);
    }, [location]);

    useEffect(() => {
        if (affiliate) localStorage.setItem("affiliate", affiliate)
    }, [affiliate])

    return (
        <>
            <ConfigProvider theme={colorState === "dark" ? {algorithm: theme.darkAlgorithm} : {}}
                            renderEmpty={() => (
                                <div className={"my-3"}>
                                    <EmptyData/>
                                </div>
                            )}
            >
                <Helmet>
                    <link rel="stylesheet" href={`./brand/color/${currentBrand}/${colorState}.css`}/>
                    <link rel="icon" href={brandSettingState?.siteFavicon}/>
                    <link rel="apple-touch-icon" href={brandSettingState?.siteFavicon}/>
                    <title>{brandSettingState?.companyName}</title>
                    <meta name="description" content={brandSettingState?.companyName}/>
                </Helmet>
                {contextHolder}
                {messageContextHolder}
                {!languageLoading && !brandLoading && !layoutLoading && !initLoading ? (
                    <>
                        <RouterComponent/>
                        {
                            userIdStorage && tokenStorage &&
                            <AddToHomeScreenModal />
                        }
                    </>
                ) : (
                    <LoadingScreen/>
                )}
            </ConfigProvider>
        </>
    );
}

export default App;
