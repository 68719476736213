import {Layout} from "antd";
import React from "react";
import {Outlet} from "react-router-dom";
import "./css/style.css";
import {Header_1} from "./component/header";
import {useRecoilValue} from "recoil";
import {globalRoutes, globalUserVerifyState} from "../../_common/global_state";
import BottomNavigationBar from "./component/bottomNavigationBar";

const Layout_1 = ({registerField}) => {
    const userVerifyState = useRecoilValue(globalUserVerifyState);
    const allRoutes = useRecoilValue(globalRoutes);

    const routes = allRoutes?.side || []
    const headerRoutes = allRoutes?.side || []
    const otherRoutes = allRoutes?.others || []
    const mobileHeaderRoutes = [...routes, ...otherRoutes]

    return (
        <Layout id={"layout-1"}>
            {userVerifyState ? (
                <>
                    <Header_1
                        registerField={registerField}
                        headerRoutes={headerRoutes}
                        mobileRoutes={mobileHeaderRoutes}
                    />
                    <Layout>
                        <Layout id={"layout-body-1"} className={"layout_padding"}>
                            <Outlet/>
                        </Layout>
                    </Layout>
                    <BottomNavigationBar/>
                </>
            ) : (
                <Outlet/>
            )}
        </Layout>
    );
};
export default Layout_1;