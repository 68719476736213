import {gql} from "@apollo/client";
import {ApolloClientQuery} from "./_setup";

const GET_USER_PREFERENCE_NAVIGATION = gql`
    query getUserPreferenceNavigation($userid:String) {
        getUserPreferenceNavigation(userid:$userid) {
            ... on UserPreferenceNavigation {
                webNavigation{
                    moduleId{
                        id
                        _id
                        name
                        categoryName
                        isEnabled
                        isEditable
                    }
                    position
                    isLocked
                }
                mobileNavigation{
                    moduleId{
                        id
                        _id
                        name
                        categoryName
                        isEnabled
                        isEditable
                    }
                    position
                    isLocked
                    type
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function getUserPreferenceNavigation(userid) {

    const variables = {
        userid: userid,
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_USER_PREFERENCE_NAVIGATION, variables, false);
    return execute;
}

const UPDATE_USER_PREFERENCE_NAVIGATION = gql`
    mutation updateUserPreferenceNavigation($input: UserPreferenceNavigationInput) {
        updateUserPreferenceNavigation(input: $input){
            ... on UserPreferenceNavigation {
                webNavigation{
                    moduleId{
                        id
                        name
#                        parent{
#                            id
#                            name
#                            isEnabled
#                            isEditable
#                        }
                        isEnabled
                        isEditable
                    }
                    position
                    isLocked
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function updateWebModuleSetting(userid, mobileNavigation) {

    const variables = {
        input: {
            user: userid,
            mobileNavigation: mobileNavigation
        },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(UPDATE_USER_PREFERENCE_NAVIGATION, variables, true);

    return execute;
}

const GET_MODULE_SETTING = gql`
    query getModuleSetting($filter:ModuleSettingSearchInput) {
        getModuleSetting(filter:$filter) {
            ... on ModuleSettingArray {
                data{
                    id
                    _id
                   name
                   categoryName
                    parent{
                        _id
                        id
                        name
                        isEnabled
                    }
                    categoryName
                    isEnabled
                    iconLightActive{
                        path
                    }
                    defaultIconDarkActive {
                        path
                    }
                    defaultIconDarkInactive {
                        path
                    }
                    defaultIconLightActive {
                        path
                    }
                    defaultIconLightInactive {
                        path
                    }
                    description
                    iconDarkActive {
                        path
                    }
                    iconDarkInactive {
                        path
                    }
                    iconLightActive {
                        path
                    }
                    iconLightInactive {
                        path
                    }
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function getModuleSetting() {

    const variables = {
        // filter: {
        //     layout: "2"
        // },
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_MODULE_SETTING, variables, false);

    return execute;
}

const GET_DEFAULT_WEB_MODULE_SETTING = gql`
   query getWebModuleSetting{
        getWebModuleSetting(filter:{hasPosition: true,moduleName: ""}){
            ... on WebModuleSettingArray {
                data {
                    position
                    _id
                    isLocked
                    moduleId {
                        _id
                        name
                    }
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function getDefaultWebModuleSetting() {

    const variables = {
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_DEFAULT_WEB_MODULE_SETTING, variables, false);

    return execute;
}

const GET_MOBILE_MODULE_SETTING = gql`
    query getMobileModuleSetting{
        getMobileModuleSetting{
            ... on MobileModuleSettingArray {
                data {
                    position
                    _id
                    isLocked
                    moduleId {
                        _id
                        name
                    }
                    type
                }
            }
            ... on BaseError {
                errKey
                errMsg
            }
        }
    }
`;

export async function getDefaultMobileModuleSetting() {

    const variables = {
        context: {
            headers: {
                authorization: "Bearer " + localStorage.getItem("a_t"),
            },
        },
    };
    const execute = await ApolloClientQuery(GET_MOBILE_MODULE_SETTING, variables, false);

    return execute;
}

