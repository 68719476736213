import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    globalBrandModuleSettingState,
    globalCurrentPathState,
    globalDefaultMobileModuleSettingState,
    globalNotificationState,
    globalRoutes,
    globalUserDetailState,
    globalWebModuleSettingState,
} from "../../_common/global_state";
import {updateWebModuleSetting} from "../../api/graphql/webModuleSetting";
import {useVerification} from "../../_common/_authorize";
import usePreload from "../../_common/_preload";
import {useTranslation} from "react-i18next";
import {goLive} from "../../api/graphql/auth";
import {getUserById} from "../../api/graphql/user";
import {useProfileFunction} from "../../pages/profile/function";
import getMobileRoute from "../../router/mobileRoute";
import useShared from "../../_common/_shared";

const useLayoutFunction = () => {
    const brandModuleSetting = useRecoilValue(globalBrandModuleSettingState);
    const defaultModuleSetting = useRecoilValue(globalDefaultMobileModuleSettingState);
    const [webModuleSetting, setWebModuleSetting] = useRecoilState(globalWebModuleSettingState);
    const globalPathState = useRecoilValue(globalCurrentPathState);
    const setRoutes = useSetRecoilState(globalRoutes);
    const {IconCommon, IconModule} = useShared();

    const [userDetails, setUserDetails] = useRecoilState(globalUserDetailState);
    const setNotificationMessage = useSetRecoilState(globalNotificationState);
    const {baseErrorChecking} = useVerification();
    const {getUserPreferenceNavigations} = usePreload();
    const {t, i18n} = useTranslation();
    const {authorizeUser} = useVerification();
    const {updateProfile} = useProfileFunction();

    function addObjectWithAutoPosition(array, newObj) {
        // Sort the array based on the position property in ascending order
        array.sort((a, b) => a.position - b.position);

        // Find the first gap in positions
        let newPosition = 1;
        for (let i = 0; i < array.length; i++) {
            if (array[i].position > newPosition) {
                break;
            }
            newPosition++;
        }

        // Add the new object with the auto-assigned position
        newObj.position = newPosition;
        array.push(newObj);

        return array;
    }

    const addNavigationFunction = async (id) => {
        try {
            let ary = [];
            await Promise.all(
                webModuleSetting.map((item) => {
                    const id = brandModuleSetting.find(
                        (items) => items.name === item?.moduleId?.name
                    )._id;
                    ary.push({
                        moduleId: id,
                        position: item?.position,
                    });
                })
            );

            const autoPosition = await addObjectWithAutoPosition(ary, {
                moduleId: id,
            });
            const response = await updateWebModuleSetting(
                userDetails._id,
                autoPosition
            );

            if (response?.updateUserPreferenceNavigation.__typename === "BaseError") {
                return await baseErrorChecking(
                    response?.updateUserPreferenceNavigation?.errKey
                );
            } else {
                await getUserPreferenceNavigations(userDetails)
            }
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        }
    };

    const removeNavigationFunction = async (id) => {
        try {
            const latestWebModule = webModuleSetting.filter(
                (item) => item?.moduleId?._id !== id
            );
            let ary = [];
            await Promise.all(
                latestWebModule.map((item) => {
                    ary.push({
                        moduleId: item?.moduleId._id,
                        position: item?.position,
                    });
                })
            );

            const response = await updateWebModuleSetting(userDetails._id, ary);

            if (response?.updateUserPreferenceNavigation.__typename === "BaseError") {
                return await baseErrorChecking(
                    response?.updateUserPreferenceNavigation?.errKey
                );
            } else {
                await getUserPreferenceNavigations(userDetails)
            }
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        }
    };

    const adjustPositionNavigationFunction = async (array) => {
        try {
            let ary = [];
            await Promise.all(
                array.map((item) => {
                    const id = brandModuleSetting.find(
                        (items) => items.name === item?.moduleId?.name
                    )._id;
                    ary.push({
                        moduleId: id,
                        position: item?.position || 1,
                    });
                })
            );

            const response = await updateWebModuleSetting(userDetails._id, ary);

            if (response?.updateUserPreferenceNavigation.__typename === "BaseError") {
                return await baseErrorChecking(
                    response?.updateUserPreferenceNavigation?.errKey
                );
            } else {
                await getUserPreferenceNavigations(userDetails)
            }
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        }
    };

    const saveNavigationFunction = async (data) => {
        try {
            const response = await updateWebModuleSetting(userDetails._id, data);

            setNotificationMessage({
                status: "success",
                title: "",
                msg: "Navigation item updated successfully",
                key: "navigationNotification",
            });

            if (response?.updateUserPreferenceNavigation.__typename === "BaseError") {
                return await baseErrorChecking(
                    response?.updateUserPreferenceNavigation?.errKey
                );
            } else {
                await getUserPreferenceNavigations(userDetails)
            }
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        }
    };

    const resetNavigationFunction = async () => {
        try {
            let ary = [];
            await Promise.all(
                defaultModuleSetting?.filter(d => d?.position)?.map((item) => {
                    ary.push({
                        moduleId: item?.moduleId?._id,
                        position: item?.position,
                        type: item?.type
                    });
                })
            );
            const response = await updateWebModuleSetting(userDetails._id, ary);
            setNotificationMessage({
                status: "success",
                title: "",
                msg: "Navigation item has reset to default",
                key: "navigationError",
            });

            if (response?.updateUserPreferenceNavigation.__typename === "BaseError") {
                return await baseErrorChecking(
                    response?.updateUserPreferenceNavigation?.errKey
                );
            } else {
                await getUserPreferenceNavigations(userDetails)
            }
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        }
    };

    const goLiveFunction = async (input) => {
        try {
            delete input?.confirmPassword;
            const response = await goLive(userDetails?._id, input);

            if (response?.goLive.__typename === "BaseError") {
                return await baseErrorChecking(response?.goLive?.errKey);
            } else {
                const userDetail = await getUserById(userDetails?._id);
                setUserDetails(userDetail?.getUserByID);
                if (userDetail?.getUserByID?.clientAccountType === "individual") {
                    setNotificationMessage({
                        status: "success",
                        title: "",
                        msg: "Account Go Live Successfully",
                        key: "goLiveMsg",
                    });
                }
            }
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "System Error",
                key: "systemNotification",
            });
        }
    };

    const parseIconName = (iconName = "", type, layout) => {
        // if (layout === 6) {
        //   return `${type}-${iconName?.replaceAll(" ", "-")?.replaceAll(".", "").toLowerCase()}-regular`;
        // } else
        if (layout === 5 || layout === 1 || layout === 6) {
            return `${type}-${iconName?.replaceAll(" ", "-")?.replaceAll(".", "").replaceAll("-", "").toLowerCase()}`;
        }
        return iconName;
    };

    const changeLanguageHandler = async (lang) => {
        try {
            if (!localStorage.getItem("userid")) {
                localStorage.setItem("language", lang);
                i18n.changeLanguage(lang);
                window.location.reload(false);

                return
            }

            const response = await updateProfile(localStorage.getItem("userid"), {language: lang}, true)
            if (response?.__typename === "User") {
                localStorage.setItem("language", lang);
                i18n.changeLanguage(lang);
                window.location.reload(false);
            }
        } catch (e) {
            console.error(e)
        }
    };

    function filterAndGroupServers(arr) {
        const result = {};
        let appObject = null;

        for (const obj of arr) {
            const serverName = obj?.server?.name;
            const isEnabled =
                obj?.windowLinkObject.isEnabled ||
                obj?.macOSXLinkObject.isEnabled ||
                obj?.androidAPKLinkObject.isEnabled ||
                obj?.iOSDownloadLinkObject.isEnabled ||
                obj?.androidDownloadLinkObject.isEnabled ||
                obj?.huaweiDownloadLinkObject.isEnabled;

            if (obj.type === "app" && isEnabled) {
                appObject = obj;
            }

            if (serverName && isEnabled) {
                result[serverName] = obj;
            }
        }

        if (appObject) {
            result["App"] = appObject;
        }

        return result;
    }

    const preloadRoute = async (brandConfigState, brandModuleSetting, mobileModuleSetting) => {
        const { bottomNavigation, mainNavigation } = await getMobileRoute(
            brandConfigState,
            brandModuleSetting,
            mobileModuleSetting,
            false
        );

        const processItem = (item, isBottom = false) => {
            let newItem = { ...item };

            if (newItem?.sub?.length > 0) {
                newItem.sub = newItem.sub.map(sub => ({
                    ...sub,
                    icon: (
                        <IconModule
                            layout={brandConfigState?.defaultLayout}
                            isAlwaysActive={globalPathState === sub.path}
                            className="sidebarIcon"
                            iconName={parseIconName(
                                sub.key,
                                sub.key === "Wallet" && ![5, 6].includes(brandConfigState?.defaultLayout) ? "cm" : "md",
                                brandConfigState?.defaultLayout
                            )}
                        />
                    ),
                }));
            }

            const isSpecialLayout = ![1, 5].includes(brandConfigState?.defaultLayout);
            const isHomeOrMore = newItem.key === "home" || newItem.key === "more";
            const iconName = parseIconName(
                newItem.key,
                isHomeOrMore && isSpecialLayout ? "cm" : "md",
                brandConfigState?.defaultLayout
            );

            newItem.label = t(`module.${newItem.label.replace(".", "").replace(/[-\s/+]/g, "_").toLowerCase()}`);
            newItem.icon = (
                (isHomeOrMore && isSpecialLayout) ? (
                    <IconCommon
                        className="sidebarIcon"
                        iconName={iconName}
                        isAlwaysActive={globalPathState === newItem.path}
                        isModule={true}
                    />
                ) : (
                    <IconModule
                        layout={brandConfigState?.defaultLayout}
                        className="sidebarIcon"
                        iconName={iconName}
                        isAlwaysActive={globalPathState === newItem.path}
                    />
                )
            );

            return newItem;
        };

        const routes = await Promise.all(mainNavigation?.map(item => processItem(item)));
        const routesBottom = await Promise.all(bottomNavigation?.map(item => processItem(item, true)));

        setRoutes({
            side: routes,
            bottom: routesBottom,
            others: routes.find(d => d.key === "more")?.sub,
        });
    };

    return {
        addNavigationFunction,
        removeNavigationFunction,
        saveNavigationFunction,
        resetNavigationFunction,
        adjustPositionNavigationFunction,
        goLiveFunction,
        parseIconName,
        changeLanguageHandler,
        filterAndGroupServers,
        preloadRoute
    };
};

export default useLayoutFunction;
