import React, {useEffect, useState} from "react";
import {Avatar, Button, Col, Divider, Drawer, Layout, Popover, Row} from "antd";
import {useRecoilState, useRecoilValue} from "recoil";
import {
    globalBrandSettingState, globalBreadCrumbState,
    globalColorThemeState,
    globalLanguageListState,
    globalOpenGoLiveModalState,
    globalUserDetailState,
} from "../../../_common/global_state";
import QrModal from "./qr/qrModal";
import PlatformDownloadModal from "./platformDownload/platformDownloadModal";
import {getPlatform} from "../../../api/graphql/platformDownload";
import dummyImg from "../../../assets/image/dummy.png";
import {useLocation, useNavigate} from "react-router-dom";
import useShared from "../../../_common/_shared";
import {useTranslation} from "react-i18next";
import GoLiveModal from "./goLive";
import {useVerification} from "../../../_common/_authorize";
import useLayoutFunction from "../../function";
import FooterTime from "./latestTime/footerTime";

export const Header_1 = (props) => {
    const {registerField, mobileRoutes} = props;
    const {t} = useTranslation();
    const {handleTokenExpiration} = useVerification();
    const {Header} = Layout;
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [openQrModal, setOpenQrModal] = useState(false);
    const [openGoLiveModal, setOpenGoLiveModal] = useRecoilState(globalOpenGoLiveModalState);
    const [openPlatformModal, setOpenPlatformModal] = useState(false);
    const [platformData, setPlatformData] = useState([]);
    const userDetails = useRecoilValue(globalUserDetailState);
    const brandSettingState = useRecoilValue(globalBrandSettingState);
    const languageListState = useRecoilValue(globalLanguageListState);
    const [colorState, setColorState] = useRecoilState(globalColorThemeState);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const {IconCommon, IconFlag} = useShared();
    const {changeLanguageHandler} = useLayoutFunction();
    const location = useLocation();
    const globalBreadCrumb = useRecoilValue(globalBreadCrumbState);

    const [popoverContentConfigKey, setPopoverContentConfig] = useState();

    const onClose = () => {
        setOpen(false);
    };

    function filterAndGroupServers(arr) {
        const result = {};
        let appObject = null;

        for (const obj of arr) {
            const serverName = obj?.server?.name;
            const isEnabled =
                obj?.windowLinkObject.isEnabled ||
                obj?.macOSXLinkObject.isEnabled ||
                obj?.androidAPKLinkObject.isEnabled ||
                obj?.iOSDownloadLinkObject.isEnabled ||
                obj?.androidDownloadLinkObject.isEnabled ||
                obj?.huaweiDownloadLinkObject.isEnabled;

            if (obj.type === "app" && isEnabled) {
                appObject = obj;
            }

            if (serverName && isEnabled) {
                result[serverName] = obj;
            }
        }

        if (appObject) {
            result["App"] = appObject;
        }

        return result;
    }

    const preload = async () => {
        const response = await getPlatform();
        const filtered = await filterAndGroupServers(response?.getPlatform?.data);
        setPlatformData(filtered);
    };

    useEffect(() => {
        preload();
    }, []);

    // const popoverContent = (
    //     <div>
    //         <div className="avatar-section">
    //             <Avatar className="profile-avatar" size={48} src={userDetails?.profileImage || dummyImg}/>
    //             <div>
    //                 <div className="username">{userDetails?.username}</div>
    //                 <div className="accountTypeBadge">{t(`user.statusTag.${userDetails?.clientAccountType}`)}</div>
    //             </div>
    //         </div>
    //         {userDetails?.clientAccountType === "demo" ? (
    //             <Button
    //                 type="primary"
    //                 className="goLiveButton"
    //                 onClick={() => {
    //                     setOpenGoLiveModal(true);
    //                 }}
    //             >
    //                 {t("common.goLive")}
    //             </Button>
    //         ) : null}
    //         <div className="popover-select-item-list mt-2">
    //             <Button
    //                 type="text"
    //                 onClick={() => {
    //                     setOpenQrModal(true);
    //                     setPopoverOpen(false);
    //                 }}
    //             >
    //                 {t("referral.modal.title")}
    //             </Button>
    //             <Button
    //                 type="text"
    //                 onClick={() => {
    //                     navigate("/profile");
    //                 }}
    //             >
    //                 {t("common.editProfile")}
    //             </Button>
    //             <Button
    //                 type="text"
    //                 onClick={() => {
    //                     handleTokenExpiration();
    //                 }}
    //             >
    //                 {t("common.logOut")}
    //             </Button>
    //         </div>
    //     </div>
    // );

    const platformDowloadKey = platformData ? Object.entries(platformData) : [];

    const getNestedPopoverContent = (key) => {
        if (platformDowloadKey.some((k) => k[0] === key)) {
            const platforms = Object.entries(platformData[key]).reduce(
                (a, b) => (b?.[1]?.__typename === "LinkObject" && b?.[1]?.isEnabled && b?.[1]?.link ? [...a, b] : a),
                []
            );
            return (
                <>
                    {platforms.map((p, i) => (
                        <Button
                            key={i}
                            type="text"
                            onClick={() => {
                                window.open(p?.[1]?.link, "_blank");
                            }}
                        >
                            {p?.[1]?.displayName}
                        </Button>
                    ))}
                </>
            );
        }

        switch (key) {
            case "language":
                return (
                    <>
                        {(languageListState ?? [])?.map((lang) => (
                            <Button key={lang?.nativeName} type="text"
                                    onClick={() => changeLanguageHandler(lang?.code)}>
                                <IconFlag countryCode={lang?.countryCode} style={{width: "24px", marginRight: "8px"}}/>
                                <div>{lang?.nativeName}</div>
                            </Button>
                        ))}
                    </>
                );
            case "theme":
                return (
                    <>
                        <Button
                            type="text"
                            onClick={() => {
                                setColorState("light");
                                localStorage.setItem("color", "light");
                            }}
                        >
                            {t("common.light")}
                        </Button>
                        <Button
                            type="text"
                            onClick={() => {
                                setColorState("dark");
                                localStorage.setItem("color", "dark");
                            }}
                        >
                            {t("common.dark")}
                        </Button>
                    </>
                );

            case "download":
                return (
                    <>
                        {platformDowloadKey.map(([k], i) => (
                            <Button
                                key={i}
                                type="text"
                                onClick={() => {
                                    setPopoverContentConfig(k);
                                }}
                            >
                                {k}
                            </Button>
                        ))}
                    </>
                );

            default:
                return (
                    <>
                        <Button
                            type="text"
                            onClick={() => {
                                setPopoverContentConfig("language");
                            }}
                        >
                            {t("common.language")}
                        </Button>
                        <Button
                            type="text"
                            onClick={() => {
                                setPopoverContentConfig("theme");
                            }}
                        >
                            {t("common.theme")}
                        </Button>
                        <Button
                            type="text"
                            onClick={() => {
                                navigate("/profile");
                                // Open bookmark modal
                                // setPopoverContentConfig("bookmark");
                            }}
                        >
                            {t("common.bookmark")}
                        </Button>
                        {platformDowloadKey?.length ? (
                            <Button
                                type="text"
                                onClick={() => {
                                    setPopoverContentConfig("download");
                                }}
                            >
                                {t("common.download")}
                            </Button>
                        ) : null}
                        <>
                            <Button
                                type="text"
                                onClick={() => {
                                    setOpenQrModal(true);
                                    setPopoverOpen(false);
                                }}
                            >
                                {t("referral.modal.title")}
                            </Button>
                            <Button
                                type="text"
                                onClick={() => {
                                    navigate("/profile");
                                }}
                            >
                                {t("common.editProfile")}
                            </Button>
                            <Button
                                type="text"
                                onClick={() => {
                                    handleTokenExpiration();
                                }}
                            >
                                {t("common.logOut")}
                            </Button>
                        </>
                    </>
                );
        }
    };

    const popoverContentConfig = (
        <div>
            {popoverContentConfigKey && (
                <div className="popoverNestedHeader">
                    <Button
                        type="text"
                        className="popoverBackButton"
                        onClick={() => {
                            if (platformDowloadKey.some((k) => k[0] === popoverContentConfigKey)) {
                                setPopoverContentConfig("download");
                            } else {
                                setPopoverContentConfig();
                            }
                        }}
                    >
                        &lt;
                    </Button>
                    <div>
                        {popoverContentConfigKey === platformDowloadKey?.[0]?.[0] ? popoverContentConfigKey : t(`common.${popoverContentConfigKey}`)}
                    </div>
                </div>
            )}

            <div className="popover-select-item-list">{getNestedPopoverContent(popoverContentConfigKey)}</div>
        </div>
    );

    const formatMobileHeaderRoutes = () => {
        const mobileHeaderRoutes = {};
        mobileRoutes?.forEach((item) => {
            const categoryName = item?.categoryName || "common"; // Set to "common" if categoryName is not defined
            if (!mobileHeaderRoutes[categoryName]) {
                mobileHeaderRoutes[categoryName] = [];
            }
            if (item?.path) mobileHeaderRoutes[categoryName].push(item);
        });

        return mobileHeaderRoutes;
    };

    const mobileHeaderRoutes = formatMobileHeaderRoutes();

    return (
        <Header id={"layout-header"} className={"user-select-none"}>
            <Row justify={"space-between"} align={"middle"} wrap={false}>
                <Col>
                    {location?.pathname === "/dashboard" ? (
                        <img
                            alt={""}
                            className={"clickable"}
                            onClick={() => navigate("/dashboard")}
                            height={20}
                            src={colorState === "dark" ? brandSettingState?.siteFaviconInverse : brandSettingState?.siteFavicon}
                        />
                    ) : (
                        <IconCommon
                            className={"header-icon"}
                            iconName={"cm-arrowleftlong-regular"}
                            onClick={() => navigate(-1)}
                        />
                    )}
                </Col>
                {location?.pathname !== "/dashboard" && (
                    <div className={"text-small text-weight-medium"}>
                        {globalBreadCrumb?.[globalBreadCrumb?.length - 1]?.name}
                    </div>
                )}
                <IconCommon
                    onClick={() => setOpen(true)}
                    iconName={"cm-hamburger-regular"}
                    className={"header-icon"}
                />
                <Drawer
                    className={"header-drawer"}
                    getContainer={() => document.querySelector("#layout-1")}
                    title={
                        <Row className={"header-drawer-title"} justify={"space-between"} align={"middle"}
                             wrap={false}>
                            <Col>
                                <img
                                    alt={""}
                                    className={"clickable"}
                                    onClick={() => navigate("/dashboard")}
                                    height={20}
                                    src={colorState === "dark" ? brandSettingState?.siteFaviconInverse : brandSettingState?.siteFavicon}
                                />
                            </Col>
                            <IconCommon onClick={() => setOpen(false)} iconName={"cm-close-regular"}
                                        className={"header-icon"}/>
                        </Row>
                    }
                    placement={"right"}
                    closable={false}
                    onClose={onClose}
                    open={open}
                    width={"100%"}
                >
                    <Row justify={"space-between"} align={"top"}>
                        <div className="avatar-section">
                            <Avatar className="profile-avatar me-2" size={48}
                                    src={userDetails?.profileImage || dummyImg}/>
                            <div>
                                <div className="username">{userDetails?.username}</div>
                                <div
                                    className="accountTypeBadge">{t(`user.statusTag.${userDetails?.clientAccountType}`)}</div>
                            </div>
                        </div>
                        <Popover
                            trigger={"click"}
                            content={popoverContentConfig}
                            getPopupContainer={() => document.querySelector("#layout-1")}
                            onOpenChange={(e) => {
                                if (!e) {
                                    setPopoverContentConfig();
                                }
                            }}
                        >
                            <div className="header-config">
                                <IconCommon iconName={"cm-setting-regular"}/>
                            </div>
                        </Popover>
                    </Row>
                    {userDetails?.clientAccountType !== "demo" ? (
                        <Button
                            className={"mt-3"}
                            type="primary"
                            block
                            onClick={() => {
                                setOpenGoLiveModal(true);
                            }}
                        >
                            {t("common.goLive")}
                        </Button>
                    ) : null}
                    <div className={"mt-3"}>
                        {Object.keys(mobileHeaderRoutes)?.map((item) => (
                            <div key={item} className={"mt-4"}>
                                <p className={"drawer-section-title"}>{t(`common.${item}`)}</p>
                                <Divider className={"mt-1 mb-3"}/>
                                <Row gutter={[16, 16]}>
                                    {mobileHeaderRoutes[item]?.map((d) => (
                                        <Col
                                            span={8}
                                            key={d?.key}
                                            onClick={() => {
                                                d?.type === "_blank" ? window.open(d?.path, "_blank") : navigate(d?.path);
                                                setOpen(false);
                                            }}
                                        >
                                            {d?.icon}
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        ))}
                    </div>
                    <p className={"text-center mt-5"}>
                        <FooterTime/>
                    </p>
                </Drawer>
            </Row>
            <QrModal
                isModalOpen={openQrModal}
                handleCancel={() => setOpenQrModal(false)}
                getPopupContainer={() => document.querySelector("#layout-1")}
            />
            <PlatformDownloadModal
                isModalOpen={openPlatformModal}
                handleCancel={() => setOpenPlatformModal(false)}
                platformData={platformData}
                getPopupContainer={() => document.querySelector("#layout-1")}
            />
            <GoLiveModal
                isModalOpen={openGoLiveModal}
                handleCancel={() => setOpenGoLiveModal(false)}
                registerField={registerField}
                getPopupContainer={() => document.querySelector("#layout-1")}
            />
        </Header>
    );
};
