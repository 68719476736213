import {getMemberInitialData, getMemberInitialDataWithoutToken} from "../api/graphql/initialDataSetup";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import i18next from "i18next";
import {
    globalAvailableProductState,
    globalBrandConfigState,
    globalBrandModuleSettingLoadingState,
    globalBrandModuleSettingState,
    globalBrandSettingState,
    globalDashboardEducationState,
    globalDashboardMarketNewsLoadingState,
    globalDashboardMarketNewsState,
    globalDashboardNewsletterState,
    globalDashboardOpportunityState,
    globalDashboardPromotionState,
    globalDashboardSymbolPriceState,
    globalDefaultMobileModuleSettingState,
    globalEducationPostingState, globalEWalletSettingState,
    globalHasProductState,
    globalIconListState,
    globalLanguageListState,
    globalMobileModuleSettingState,
    globalNotificationState,
    globalProfileSetting,
    globalReferralSettingState,
    globalSymbolCategoryState,
    globalUserDefaultTradingAccount,
    globalUserSettingsState,
    globalUserTradingAccount,
    globalWebModuleSettingState,
} from "./global_state";
import {getUserFavouriteSymbol} from "../api/graphql/favouriteSymbol";
import {getTradingAccount} from "../api/graphql/tradingAccount";
// import {getProfileInformationSetting} from "../api/graphql/profileInformation";
import {getEducationCategory, getEducationPosting,} from "../api/restful/education";
import {getMarketNews} from "../api/graphql/marketNews";
import {getOpportunities} from "../api/graphql/tradeIdeas";
import {getNewsletter} from "../api/graphql/newsletter";
import {getPromotions} from "../api/restful/promotion";
import {getInstruments, getSymbolPrice, getSymbolPriceMt5} from "../api/restful/symbol-price";
import {getDefaultMobileModuleSetting, getUserPreferenceNavigation,} from "../api/graphql/webModuleSetting";
import {getReferralSetting} from "../api/graphql/referralSetting";
import useShared from "./_shared";
import {getSymbolMappingCategory} from "../api/graphql/symbolCategory";
import {categoryFakeData} from "../../config/categoryFakeData";
import {getUserSettingByUserID} from "../api/graphql/user";

function usePreload() {
    const [brandSettingState, setBrandSettingState] = useRecoilState(globalBrandSettingState);
    const setLanguageListState = useSetRecoilState(globalLanguageListState);
    const setUserSettings = useSetRecoilState(globalUserSettingsState);
    const setUserTradingAccountState = useSetRecoilState(globalUserTradingAccount);
    const setUserDefaultTradingAccountState = useSetRecoilState(globalUserDefaultTradingAccount);
    const setProfileSetting = useSetRecoilState(globalProfileSetting);
    const setNotificationMessage = useSetRecoilState(globalNotificationState);
    const setDashboardEducation = useSetRecoilState(globalDashboardEducationState);
    const setDashboardMarketNews = useSetRecoilState(globalDashboardMarketNewsState);
    const setDashboardMarketNewsLoading = useSetRecoilState(globalDashboardMarketNewsLoadingState);
    const setDashboardOpportunity = useSetRecoilState(globalDashboardOpportunityState);
    const setDashboardNewsletter = useSetRecoilState(globalDashboardNewsletterState);
    const setDashboardPromotion = useSetRecoilState(globalDashboardPromotionState);
    const setDashboardSymbolPrice = useSetRecoilState(globalDashboardSymbolPriceState);
    const brandConfigState = useRecoilValue(globalBrandConfigState);
    const setIconListState = useSetRecoilState(globalIconListState);
    const setWebModuleSetting = useSetRecoilState(globalWebModuleSettingState);
    const setMobileModuleSetting = useSetRecoilState(globalMobileModuleSettingState);
    const setBrandModuleSetting = useSetRecoilState(globalBrandModuleSettingState);
    const setBrandModuleSettingLoading = useSetRecoilState(globalBrandModuleSettingLoadingState);
    const setReferralSetting = useSetRecoilState(globalReferralSettingState);
    const setDefaultMobileModuleSetting = useSetRecoilState(globalDefaultMobileModuleSettingState);
    const setEducationPosting = useSetRecoilState(globalEducationPostingState);
    const setSymbolCategoryState = useSetRecoilState(globalSymbolCategoryState);
    const setHasProduct = useSetRecoilState(globalHasProductState);
    const [availableProductState, setAvailableProductState] = useRecoilState(globalAvailableProductState);
    const [globalEWalletSetting, setEWalletSetting] = useRecoilState(globalEWalletSettingState);

    const {convertLanguageFile} = useShared();

    const cmsUrl = localStorage?.getItem("cmsUrl");

    const getInitialDataWithoutToken = async () => {
        try {
            if (brandSettingState?.defaultDomain) return

            const initialDataWithoutToken = await getMemberInitialDataWithoutToken();

            const getLanguage = initialDataWithoutToken?.getLanguage?.data
            const getLanguageDropdown = initialDataWithoutToken?.getLanguageDropdown?.data
            const getUserBrandSetting = initialDataWithoutToken?.getUserBrandSetting
            const getIconList = initialDataWithoutToken?.getIconList?.data
            const getReferralSetting = initialDataWithoutToken?.getReferralSetting

            if (getLanguage) {
                let languageData = getLanguage;
                let languageFile = convertLanguageFile(languageData);
                window.lang = languageData;
                languageData?.forEach((item) => {
                    i18next.addResourceBundle(
                        item?.code,
                        "translation",
                        languageFile?.[item?.code]?.["translation"]
                    );
                });
            }

            if (getReferralSetting) setReferralSetting(getReferralSetting)

            if (getLanguageDropdown) {
                let languageData = getLanguageDropdown;
                let languageList = [];
                window.lang = languageData;
                languageData?.forEach((item) => {
                    languageList.push({
                        name: item?.name,
                        code: item?.code,
                        countryCode: item?.countryCode,
                        nativeName: item?.nativeName
                    });
                });
                setLanguageListState(languageList);
            }

            if (getUserBrandSetting) {
                localStorage.setItem("timezone", getUserBrandSetting.timezone)
                setBrandSettingState(getUserBrandSetting);

                const currentDomain = await window.location.hostname?.includes("localhost") ? `member.${getUserBrandSetting?.website?.replace("https://", "")?.replace(/^www\./, "")}` : window.location.hostname;
                const cmsURL = currentDomain?.replace(/member\.|stg\.|memberstg\.|mobile\.|mobilestg\.|appstg\./, "");
                localStorage.setItem("cmsUrl", cmsURL);
            }

            if (getIconList) setIconListState(getIconList);

        } catch (e) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the initial data. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getInitialData = async (userDetail) => {
        try {
            const initialData = await getMemberInitialData();
            const getAllAvailableProductByUserId = initialData?.getAllAvailableProductByUserId?.data
            // const getUserSettingByUserID = initialData?.getUserSettingByUserID?.data
            const getProfileInformationSetting = initialData?.getProfileInformationSetting?.data
            const getModuleSetting = initialData?.getModuleSetting?.data
            const eWalletSetting = initialData?.getEWalletSetting
            setEWalletSetting(eWalletSetting)

            if (getAllAvailableProductByUserId) {
                const productOptions =
                    getAllAvailableProductByUserId.map((p) => {
                        return {
                            label: p.name,
                            value: p._id,
                            productType: p?.productType,
                            minDeposit: p?.minDeposit,
                            leverage: p?.leverage,
                            server: p?.server,
                            spreadGroup: p?.spreadGroup,
                        };
                    });
                setAvailableProductState(productOptions);
            }

            if (getProfileInformationSetting) setProfileSetting(getProfileInformationSetting)

            if (getModuleSetting) {
                const filteredModule = []
                if (eWalletSetting?.depositWithKyc) {
                    filteredModule.push("Deposit")
                }

                if (eWalletSetting?.withdrawalWithKyc) {
                    filteredModule.push("Withdrawal")
                }

                setBrandModuleSettingLoading(true)
                const amendedModuleSetting = userDetail?.isKycApproved ? getModuleSetting : getModuleSetting?.filter((r) => !filteredModule?.includes(r?.name))
                await setBrandModuleSetting(amendedModuleSetting)
            }

            setBrandModuleSettingLoading(false);
        } catch (e) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the initial data. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getDashboardEducationPosting = async () => {
        try {
            const response = await getEducationPosting();

            setEducationPosting(response.data);
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the education posting details. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const defaultMobileModuleSetting = async () => {
        try {
            const getDefaultSetting = await getDefaultMobileModuleSetting();
            setDefaultMobileModuleSetting(getDefaultSetting?.getMobileModuleSetting?.data);
        } catch (e) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the default module. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getReferralDataSetting = async () => {
        try {
            const referral = await getReferralSetting();
            setReferralSetting(referral?.getReferralSetting);
        } catch (e) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the referral module. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getDefaultTradingAccount = async () => {
        try {
            const userId = localStorage.getItem("userid") || null;
            const tradingAccounts = await getTradingAccount(userId);
            const accounts = tradingAccounts?.getTradingAccount?.data;
            const defaultAccount =
                accounts.find(
                    (item) => item?.mode === "live" && item?.isDefault === true
                ) || accounts.find((item) => item?.mode === "live");

            setUserTradingAccountState(accounts);
            setUserDefaultTradingAccountState(defaultAccount);

            return defaultAccount;
        } catch (e) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the default trading account. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getDashboardEducation = async () => {
        try {
            const response = await getEducationCategory();
            setDashboardEducation(response.data);
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the education details. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getDashboardMarketNews = async () => {
        try {
            setDashboardMarketNewsLoading(true)
            const response = await getMarketNews();
            setDashboardMarketNews(response.data);
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the market news details. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        } finally {
            setDashboardMarketNewsLoading(false)
        }
    };

    const getDashboardOpportunity = async (baseSymbolId) => {
        try {
            const response = await getOpportunities(
                localStorage.getItem("userid"),
                null,
                baseSymbolId || null,
                6
            );
            setDashboardOpportunity(response?.article);
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the opportunities details. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getDashboardNewsletter = async () => {
        try {
            const response = await getNewsletter();
            setDashboardNewsletter(response?.getNewsletter?.data);
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the newsletter details. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getDashboardPromotion = async () => {
        try {
            if (cmsUrl) {
                const response = await getPromotions();
                const filtered = response?.data.filter((item) => item?.active === true);
                // if (filtered.length < 4) {
                //     setDashboardPromotion([...filtered, ...filtered]);
                // } else {
                setDashboardPromotion(filtered);
                // }
            }
        } catch (e) {
            console.log("Error:", e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the promotion details. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getDashboardSymbolPrice = async (serverData, spreadGroup) => {
        try {
            const userId = localStorage.getItem("userid") || null;
            if (userId && serverData) {
                const userFavouriteSymbol = await getUserFavouriteSymbol(
                    userId,
                    serverData?._id
                );

                let symbol = [];

                if (serverData?.platform === "cTrader") {
                    const baseSymbolId = userFavouriteSymbol?.getUserFavouriteSymbol?.favouriteSymbol.map(d => d?.baseSymbolId) || []
                    let serverName = serverData?.plantId === "blueocean" ? "server2" : "server1"

                    const symbolPrice = await getSymbolPrice(
                        null,
                        spreadGroup,
                        serverName
                    );

                    const baseInstrumentData = await getInstruments(baseSymbolId.join(","));

                    await Promise.all(
                        [...userFavouriteSymbol?.getUserFavouriteSymbol?.favouriteSymbol]?.map(async (item) => {
                            const detail = await baseInstrumentData?.find((symbol) => symbol?.base_symbol_id === Number(item?.baseSymbolId));
                            const price = await symbolPrice?.payload?.find((v) => v?.symbolId === Number(item?.symbolId));

                            symbol.push({
                                symbolName: item?.name,
                                ...price,
                                baseSymbolId: item?.baseSymbolId,
                                icon: detail?.icon_link || detail?.[0]?.icon_link || null,
                                instrumentName: detail?.instrument_name,
                            });
                        })
                    );

                } else if (brandConfigState?.tradeURL) {
                    const baseSymbolId = userFavouriteSymbol?.getUserFavouriteSymbol?.favouriteSymbolMt5.map(d => d?.baseSymbolId) || []
                    const baseInstrumentData = await getInstruments(baseSymbolId.join(","));
                    const symbolPrice = await getSymbolPriceMt5(brandConfigState?.tradeURL, null, spreadGroup)

                    // const decryptedSymbolPrice = decryptMT5Message(symbolPrice)

                    await Promise.all(
                        [...userFavouriteSymbol?.getUserFavouriteSymbol?.favouriteSymbolMt5]?.map(async (item) => {
                            const detail = await baseInstrumentData?.find((symbol) => symbol?.base_symbol_id === Number(item?.baseSymbolId));
                            const price = await symbolPrice?.payload?.data?.find((v) => v?.symbol === item?.symbol);

                            symbol.push({
                                symbolName: price?.symbol,
                                ...price,
                                baseSymbolId: item?.baseSymbolId,
                                icon: detail?.icon_link || detail?.[0]?.icon_link || null,
                                instrumentName: detail?.instrument_name,
                            });
                        })
                    );

                }

                setDashboardSymbolPrice(symbol);
            }
        } catch (e) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the symbol price details. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getSymbolMappingCategories = async (serverData) => {
        try {
            const userId = localStorage.getItem("userid") || null;
            if (userId && serverData) {
                const symbolCategory = await getSymbolMappingCategory(serverData?.name);
                const symbolCategoryData = structuredClone(
                    symbolCategory?.getSymbolMappingCategory?.data ?? []
                );
                const baseSymbolIds = symbolCategoryData?.map((c) =>
                    c?.symbol?.map((s) => s?.baseSymbolId)
                );

                // Fetch instrument for icons
                const instruments = await getInstruments(baseSymbolIds.join(","));
                const instrumentMap =
                    instruments?.reduce(
                        (a, b) => ({...a, [b.base_symbol_id]: b}),
                        {}
                    ) || {};

                symbolCategoryData?.forEach((c) => {
                    (c.symbol || [])?.forEach((s) => {
                        const bsi = s.baseSymbolId;
                        s.icon = instrumentMap[bsi]?.icon_link ?? undefined;
                        // c.symbol = { ...c.symbol, icon: instrumentMap[bsi] ?? undefined };
                    });
                });

                setSymbolCategoryState(symbolCategoryData);
            }
        } catch (e) {
            console.error(e);
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the symbol price details. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        }
    };

    const getUserPreferenceNavigations = async (userDetail) => {
        try {
            const module = await getUserPreferenceNavigation(userDetail?._id);
            const navList = module?.getUserPreferenceNavigation?.webNavigation
            const navListMobile = module?.getUserPreferenceNavigation?.mobileNavigation

            const filteredModule = []
            if (globalEWalletSetting?.depositWithKyc) {
                filteredModule.push("Deposit")
            }

            if (globalEWalletSetting?.withdrawalWithKyc) {
                filteredModule.push("Withdrawal")
            }

            const amendedNavList = (userDetail?.isKycApproved || userDetail?.isKybApproved) ? navList : navList?.filter((r) => !filteredModule?.includes(r?.moduleId?.name))
            const amendedNavListMobile = (userDetail?.isKycApproved || userDetail?.isKybApproved) ? navListMobile : navListMobile?.filter((r) => !filteredModule?.includes(r?.moduleId?.name))

            setWebModuleSetting(amendedNavList);
            setMobileModuleSetting(amendedNavListMobile)
        } catch (e) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the web module setting. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        } finally {
        }
    };

    const getUserSettings = async (userId) => {
        try {
            const response = await getUserSettingByUserID(userId);
            setUserSettings(response?.getUserSettingByUserID);
        } catch (e) {
            setNotificationMessage({
                status: "error",
                title: "",
                msg: "Apologies, we are currently undergoing maintenance for the web module setting. We're actively working to resolve the issue.",
                key: "systemNotification",
            });
        } finally {
        }
    };

    const preload = async () => {
        const userId = localStorage.getItem("userid") || null;
        const language = localStorage.getItem("language") || "en";
        if (!language) {
            localStorage.setItem("language", "en");
        } else {
            i18next.changeLanguage(language);
        }

        if (brandConfigState?.backendURL) {
            if (userId) {
                const tradingAcc = await getDefaultTradingAccount();
                const products = availableProductState;
                let serverData, spreadGroup
                if (tradingAcc) {
                    serverData = tradingAcc?.product?.server
                    if (serverData?.platform === "cTrader") {
                        spreadGroup = tradingAcc?.spreadGroup?.name
                    } else {
                        spreadGroup = tradingAcc?.mt5SpreadGroup?.group
                    }
                } else {
                    serverData = products?.find((p) => p?.productType === "selfTradeLive")?.server
                    if (serverData?.platform === "cTrader") {
                        spreadGroup = products?.find((p) => p?.productType === "selfTradeLive")?.spreadGroup?.[0]?.name
                    } else {
                        spreadGroup = products?.find((p) => p?.productType === "selfTradeLive")?.spreadGroupMt5?.[0]?.group
                    }
                }

                if (serverData) {
                    localStorage.setItem("platform", serverData?.platform)
                    getDashboardSymbolPrice(serverData, spreadGroup);
                    await getSymbolMappingCategories(serverData);
                    setHasProduct(true);
                } else {
                    localStorage.setItem("platform", "cTrader")
                    setHasProduct(false);
                    setSymbolCategoryState(categoryFakeData);
                }
                await getUserSettings(userId);
                // await getUserPreferenceNavigations(userId);
            }
            await defaultMobileModuleSetting();
        }
    };

    return {
        preload,
        getInitialDataWithoutToken,
        getInitialData,
        // getBrandSetting,
        // getBrandModuleSetting,
        getUserPreferenceNavigations,
        // getLanguages,
        defaultMobileModuleSetting,
        getReferralDataSetting,
        getDashboardMarketNews,
        getDashboardEducation,
        getDashboardEducationPosting,
        getDashboardOpportunity,
        getDashboardNewsletter,
        getDashboardPromotion,
        // getIconLists,
        getDashboardSymbolPrice,
        // getAvailableProduct,
    };
}

export default usePreload;
