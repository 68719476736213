const MODE_PRODUCTION = true;

const domain = window.location.hostname;

let BRAND_NAME;

switch (domain) {
    case "localhost":
        BRAND_NAME = "antos";
        break;

    case "mobile.excotrades.com":
    case "mobilestg.excotrades.com":
        BRAND_NAME = "excotrades";
        break;

    // case "member.ridgewaymarkets.com":
    // case "member.ridgewaymarkets.net":
    // case "memberstg.ridgewaymarkets.com":
    //     BRAND_NAME = "ridgewaymarkets";
    //     break;
    //
    // case "member.alliumtrades.com":
    // case "memberstg.alliumtrades.com":
    //     BRAND_NAME = "alliumtrades";
    //     break;
    //
    // case "member.metapromarkets.com":
    // case "memberstg.metapromarkets.com":
    //     BRAND_NAME = "metapromarkets";
    //     break;
    //
    // case "member.fundamarkets.com":
    // case "member.fundamarkets.net":
    // case "memberstg.fundamarkets.com":
    // case "memberstg.fundamarkets.net":
    //     BRAND_NAME = "fundamarkets";
    //     break;
    //
    // case "member.minosfutures.com":
    // case "memberstg.minosfutures.com":
    // case "member.minosbrokers.com":
    // case "memberstg.minosbrokers.com":
    //     BRAND_NAME = "minosmarkets";
    //     break;
    //
    // case "member.cronoscap.com":
    // case "member.cronoscap.net":
    // case "memberstg.cronoscap.com":
    // case "memberstg.cronoscap.net":
    //     BRAND_NAME = "cronoscap";
    //     break;
    //
    // case "member.qvmarkets.com":
    // case "memberstg.qvmarkets.com":
    //     BRAND_NAME = "quorvex";
    //     break;

    case "member.blueocex.com":
    case "member.blueoceanfin.com":
        BRAND_NAME = "blueocean";
        break;

    case "appstg.antos.io":
    case "mobile.antos.io":
    case "mobilestg.antos.io":
    case "mobile.antospnc.com":
    case "mobilestg.antospnc.com":
        BRAND_NAME = "antos";
        break;
}

export {BRAND_NAME, MODE_PRODUCTION};
