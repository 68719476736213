import {atom} from "recoil";

export const selfBankInfoAccountState = atom({
    key: "selfBankInfoAccountState",
    default: [],
});

export const loginHistoryLimitState = atom({
    key: "loginHistoryLimitState",
    default: 15,
});

export const loginHistoryOffsetState = atom({
    key: "loginHistoryOffsetState",
    default: 0,
});

export const loginHistoryOrderByState = atom({
    key: "loginHistoryOrderByState",
    default: "loginTime_DESC",
});

export const loginHistoryLoadingState = atom({
    key: "loginHistoryLoadingState",
    default: false,
});