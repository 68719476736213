import {atom} from "recoil";

export const globalIsLoginState = atom({
    key: "globalIsLoginState",
    default: false,
});

export const globalUserVerifyState = atom({
    key: "globalUserVerifyState",
    default: null,
});

export const globalRunAuthState = atom({
    key: "globalRunAuthState",
    default: false,
});

export const globalBrandConfigState = atom({
    key: "globalBrandConfigState",
    default: null,
});

export const globalBrandSettingState = atom({
    key: "globalBrandSettingState",
    default: {},
});

export const globalLanguageListState = atom({
    key: "globalLanguageListState",
    default: [],
});

export const globalUserDetailState = atom({
    key: "globalUserDetailState",
    default: {},
});

export const globalUserSettingsState = atom({
    key: "globalUserSettingsState",
    default: {},
});

export const globalUserWalletState = atom({
    key: "globalUserWalletState",
    default: 0,
});

export const globalUserTradingAccount = atom({
    key: "globalUserTradingAccount",
    default: [],
});

export const globalUserDefaultTradingAccount = atom({
    key: "globalUserDefaultTradingAccount",
    default: {},
});

export const globalProfileSetting = atom({
    key: "globalProfileSetting",
    default: [],
});

export const globalSymbolCategoryState = atom({
    key: "globalSymbolCategoryState",
    default: [],
});

export const globalHasProductState = atom({
    key: "globalHasProductState",
    default: true,
});

export const globalAvailableProductState = atom({
    key: "globalAvailableProductState",
    default: [],
});

export const globalNotificationState = atom({
    key: "globalNotificationState",
    default: {
        status: "",
        title: "",
        msg: "",
        key: "",
    },
});

export const globalMessageState = atom({
    key: "globalMessageState",
    default: {
        type: "",
        content: "",
        duration: "",
        key: "",
    },
});


export const globalDashboardEducationState = atom({
    key: "globalDashboardEducationState",
    default: [],
});
export const globalDashboardMarketNewsState = atom({
    key: "globalDashboardMarketNewsState",
    default: [],
});
export const globalDashboardMarketNewsLoadingState = atom({
    key: "globalDashboardMarketNewsLoadingState",
    default: false,
});
export const globalDashboardOpportunityState = atom({
    key: "globalDashboardOpportunityState",
    default: [],
});
export const globalDashboardNewsletterState = atom({
    key: "globalDashboardNewsletterState",
    default: [],
});
export const globalDashboardPromotionState = atom({
    key: "globalDashboardPromotionState",
    default: [],
});
export const globalDashboardSymbolPriceState = atom({
    key: "globalDashboardSymbolPriceState",
    default: [],
});
export const globalIconListState = atom({
    key: "globalIconListState",
    default: [],
});
export const globalWebModuleSettingState = atom({
    key: "globalWebModuleSettingState",
    default: [],
});
export const globalMobileModuleSettingState = atom({
    key: "globalMobileModuleSettingState",
    default: [],
});
export const globalBrandModuleSettingState = atom({
    key: "globalBrandModuleSettingState",
    default: [],
});
export const globalBrandModuleSettingLoadingState = atom({
    key: "globalBrandModuleSettingLoadingState",
    default: true,
});
export const globalColorThemeState = atom({
    key: "globalColorThemeState",
    default: "light",
});

export const globalReferralSettingState = atom({
    key: "globalReferralSettingState",
    default: {},
});

export const globalDefaultMobileModuleSettingState = atom({
    key: "globalDefaultMobileModuleSettingState",
    default: [],
});

export const globalEducationPostingState = atom({
    key: "globalEducationPostingState",
    default: [],
});

export const globalBreadCrumbState = atom({
    key: "globalBreadCrumbState",
    default: [],
});

export const globalUserAccountVerificationRecordValueState = atom({
    key: "globalUserAccountVerificationRecordValueState",
    default: [],
});

export const globalUserAccountVerificationRecordDocumentState = atom({
    key: "globalUserAccountVerificationRecordDocumentState",
    default: [],
});

export const globalUserAccountVerificationRecordsState = atom({
    key: "globalUserAccountVerificationRecordsState",
    default: [],
});

export const globalCurrentPathState = atom({
    key: "globalCurrentPathState",
    default: ""
})

export const globalCurrentPreloadStatusState = atom({
    key: "globalCurrentPreloadStatusState",
    default: false
})

export const globalOpenGoLiveModalState = atom({
    key: "globalOpenGoLiveModalState",
    default: false
})

export const globalPlatformState = atom({
    key: "globalPlatformState",
    default: ""
})

export const globalLanguageLoadingState = atom({
    key: "globalLanguageLoadingState",
    default: true
})

export const globalInitLoadingState = atom({
    key: "globalInitLoadingState",
    default: true
})

export const globalBankDetailsActiveKey = atom({
    key: "globalBankDetailsActiveKey",
    default: []
})

export const globalRoutes = atom({
    key: "globalRoutes",
    default: null
})

export const globalEWalletSettingState = atom({
    key: "globalEWalletSettingState",
    default: {},
});